import './App.css';
import { useEffect, useRef, useState } from 'react';
import AvatarEditor from 'react-avatar-editor';
import { Header } from './Header';

function ProfilePictureGetter(props) {
  const [image, setImage] = useState('/profile.png')
  const avatar_editor = useRef()

  function avatarSelected() {
    props.avatarSelected && props.avatarSelected(avatar_editor.current.getImage())
  }

  return <div style={{ display: "flex", "justifyContent": "center", "flexDirection": "column", "alignItems": "center", "margin": "0 auto" }}>
    <div style={{color: "red"}}>
      Pokud jste tuto stránku navštívili přes sociální sítě na telefonu, otevřete ji prosím v klasickém prohlížeči. Zpracování nebude fungovat.
    </div>
    <AvatarEditor
      image={image}
      ref={avatar_editor}
      width={250}
      height={250}
      border={0} />
    <div style={{ maxWidth: "250px", color: "#ccc", fontSize: "0.8em" }}>
      <em>
        Vyberte obrázek.
        Doporučujeme nahrávat v maximálním rozlišení 1024x1024.
        <br />
        Posouváním můžete vybrat oblast obrázku
      </em>
    </div>
    <div className='btn-group-welcome'>
      <input type="file" onChange={e => setImage(e.target.files[0])} />
      <button onClick={avatarSelected}>
	      <svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" preserveAspectRatio="xMidYMid meet" viewBox="0 0 20 20"><path fill="currentColor" d="m17.7 6.32l1.41 1.42l-3.47 3.41l-1.42-1.42l.84-.82c-.32-.76-.81-1.57-1.51-2.31l-4.61 6.59l-5.26 4.7c-.39.39-1.02.39-1.42 0l-1.2-1.21a.996.996 0 0 1 0-1.41l10.97-9.92c-1.37-.86-3.21-1.46-5.67-1.48c2.7-.82 4.95-.93 6.58-.3c1.7.66 2.82 2.2 3.91 3.58z"/></svg>
	Pokračovat
      </button>
    </div>
  </div>
}

function App() {
  const [image, setImage] = useState();
  const [imagesData, setImagesData] = useState();
  const [activeFrame, setActiveFrame] = useState();
  const [result, setResult] = useState();
  const [roundedPreview, setRoundedPreview] = useState(true);
  const imagesSourceFile = "/images.json";
  const canvas = useRef();

  /* Update preview */
  useEffect(() => {
    if (!image || !canvas.current) return;
    const current_canvas = canvas.current;
    const ctx = current_canvas.getContext("2d")
    ctx.fillStyle = "white"
    ctx.drawImage(image, 0, 0, image.width, image.height, 0, 0, current_canvas.clientWidth, current_canvas.clientHeight)
    if (activeFrame) {
      const activeFrameImage = new Image();
      activeFrameImage.src = activeFrame.file
      activeFrameImage.onload = e => {
        ctx.drawImage(e.target, 0, 0, e.target.naturalWidth, e.target.naturalHeight, 0, 0, current_canvas.clientWidth, current_canvas.clientHeight)
      }
    }
  }, [image, canvas, activeFrame])

  function loadImages(data) {
    setImagesData(data)
  }

  /* Prepare the download */
  function downloadImage() {
    const activeFrameImage = new Image();
    activeFrameImage.src = activeFrame.file
    activeFrameImage.onload = e => {
      const canvas = document.createElement("canvas")
      const size = Math.min(image.width, activeFrameImage.naturalWidth)
      canvas.width = size
      canvas.height = size
      // Get context and draw both on the canvas
      const downloadContext = canvas.getContext("2d")
      downloadContext.drawImage(image, 0, 0, image.width, image.height, 0, 0, size, size)
      downloadContext.drawImage(activeFrameImage, 0, 0, activeFrameImage.naturalWidth, activeFrameImage.naturalHeight, 0, 0, size, size)
      // Prepare the download
      const resData = canvas.toDataURL()
      setResult(resData);
      const a = document.createElement("a");
      a.href = resData;
      a.download = "ramecek.png"
      a.click();
    }
  }

  function reset() {
    setImage()
    setActiveFrame()
  }

  /* Frames data loading */
  useEffect(() => {
    fetch(imagesSourceFile).then(res => {
      res.json().then(data => {
        loadImages(data)
      })
    })
  }, [imagesSourceFile])
  /* Detect the url used and find active frame*/
  useEffect(() => {
    if (!imagesData) {
      return;
    }
    // Have a look if there is a match for this ramecek
    const match = window.location.pathname.match(/ramecek\/([^/]*)/)
    if (match && match.length >= 2) {
      setActiveFrame(imagesData.images.find(frame => frame.slug === match[1]))
    }
  }, [imagesData])

  /* Image selected */
  function onImageSelect(image) {
    window.history.pushState({}, `Rámeček ${image.name}`, `/ramecek/${image.slug}`)
    setActiveFrame(image)
  }

  /* Main render */
  return <div className='divider'>
    <header>
      <Header />
    </header>
    <main className='content'>
      {!image && <ProfilePictureGetter avatarSelected={img => setImage(img)} />}
      {result && <div style={{width: "100%", fontSize: "0.8em", color: "red", textAlign: "center"}}>
        <a href={result}><img src={result} alt="Tohle je výsledek" style={{maxWidth: "100%", display: "block", "margin": "0 auto"}} /></a> 
        <em>Tohle je výsledek. Měl by se automaticky stáhnout. Na mobilu to může zlobit, speciálně pokud používáte integrovaný prohlížeč sociální sítě. Než přijdu jak na to, použijte nějaký jiný. S pozdravem Kolega</em>
        <button onClick={() => {setResult(undefined); setActiveFrame(undefined);}}style={{display: "block", color: "#000", margin: "0 auto"}}>Znovu a lépe</button>
      </div>}
      {image && !result && <div className="wrapper">
        <div className="preview-column" style={{ display: 'flex', alignItems: 'center', flexDirection: 'column' }}>
          <div className='preview-wrapper'>
            <canvas style={{ borderRadius: roundedPreview ? "50%" : "0" }} className="preview" ref={canvas} width="300" height="300" />
          </div>
          <div>
            <label htmlFor='rounded'>Kulatý náhled</label>
            <input type="checkbox" id="rounded" checked={!!roundedPreview} onChange={e => setRoundedPreview(e.target.checked)} />
          </div>
          <div className="btn-group" style={{ display: 'flex' }}>
            {activeFrame && <button style={{ marginRight: "30px" }} onClick={downloadImage}>
              <svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" preserveAspectRatio="xMidYMid meet" viewBox="0 0 20 20"><path fill="currentColor" d="M14.01 4v6h2V2H4v8h2.01V4h8zm-2 2v6h3l-5 6l-5-6h3V6h4z" /></svg>
              Stáhnout
            </button>}
            <button onClick={reset}>
              <svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" preserveAspectRatio="xMidYMid meet" viewBox="0 0 20 20"><path fill="currentColor" d="M10.2 3.28c3.53 0 6.43 2.61 6.92 6h2.08l-3.5 4l-3.5-4h2.32a4.439 4.439 0 0 0-4.32-3.45c-1.45 0-2.73.71-3.54 1.78L4.95 5.66a6.965 6.965 0 0 1 5.25-2.38zm-.4 13.44c-3.52 0-6.43-2.61-6.92-6H.8l3.5-4c1.17 1.33 2.33 2.67 3.5 4H5.48a4.439 4.439 0 0 0 4.32 3.45c1.45 0 2.73-.71 3.54-1.78l1.71 1.95a6.95 6.95 0 0 1-5.25 2.38z" /></svg>
              Resetovat
            </button>
          </div>
        </div>
        <div className='gallery-grid'>
          {imagesData.images.map((image, index) => <div className="frame" key={index}><img style={{ display: 'block', width: '100%' }} alt={image.name} src={image.file} onClick={() => onImageSelect(image)} /></div>)}
        </div>
      </div>}
    </main>
  </div>
}

export default App;
