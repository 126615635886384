export function Header() {
    return <>
        <div className="header-body">
            <div className="header-left">
                <img alt="logo PL" src="https://paralelnilisty.cz/wp-content/themes/paralelnilisty/img/paralelnilisty.png" />
                <div className="header-text">Nikdo vás nedonutí myslet</div>
            </div>
            <div className="header-right">
                <h2>Paralelní rámečky</h2>
            </div>
        </div>
        <div className="menu">
            <a href="https://paralelnilisty.cz">
                <svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" preserveAspectRatio="xMidYMid meet" viewBox="0 0 20 20"><path fill="currentColor" d="m16 8.5l1.53 1.53l-1.06 1.06L10 4.62l-6.47 6.47l-1.06-1.06L10 2.5l4 4v-2h2v4zm-6-2.46l6 5.99V18H4v-5.97zM12 17v-5H8v5h4z" /></svg>
                Homepage
            </a>
            <a href="https://paralelnilisty.cz/mrtki-plugin/">
                <svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" preserveAspectRatio="xMidYMid meet" viewBox="0 0 20 20"><path fill="currentColor" d="m16.89 1.2l1.41 1.41c.39.39.39 1.02 0 1.41L14 8.33V18H3V3h10.67l1.8-1.8c.4-.39 1.03-.4 1.42 0zm-5.66 8.48l5.37-5.36l-1.42-1.42l-5.36 5.37l-.71 2.12z" /></svg>
                Mrtki plugin
            </a>
            <a href="https://paralelnilisty.cz/makakroku/makak-roku-2022/">
                <svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" preserveAspectRatio="xMidYMid meet" viewBox="0 0 20 20"><path fill="currentColor" d="M2 18.43c1.51 1.36 11.64-4.67 13.14-7.21c.72-1.22-.13-3.01-1.52-4.44C15.2 5.73 16.59 9 17.91 8.31c.6-.32.99-1.31.7-1.92c-.52-1.08-2.25-1.08-3.42-1.21c.83-.2 2.82-1.05 2.86-2.25c.04-.92-1.13-1.97-2.05-1.86c-1.21.14-1.65 1.88-2.06 3c-.05-.71-.2-2.27-.98-2.95c-1.04-.91-2.29-.05-2.32 1.05c-.04 1.33 2.82 2.07 1.92 3.67C11.04 4.67 9.25 4.03 8.1 4.7c-.49.31-1.05.91-1.63 1.69c.89.94 2.12 2.07 3.09 2.72c.2.14.26.42.11.62c-.14.21-.42.26-.62.12c-.99-.67-2.2-1.78-3.1-2.71c-.45.67-.91 1.43-1.34 2.23c.85.86 1.93 1.83 2.79 2.41c.2.14.25.42.11.62c-.14.21-.42.26-.63.12c-.85-.58-1.86-1.48-2.71-2.32C2.4 13.69 1.1 17.63 2 18.43z" /></svg>
                #Makak roku
            </a>
            <a href="https://paralelnilisty.cz/debilni-ankety/">
                <svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" preserveAspectRatio="xMidYMid meet" viewBox="0 0 20 20"><path fill="currentColor" d="M15.84 2.76c.25 0 .49.04.71.11c.23.07.44.16.64.25l.35-.81c-.52-.26-1.08-.39-1.69-.39c-.58 0-1.09.13-1.52.37c-.43.25-.76.61-.99 1.08C13.11 3.83 13 4.38 13 5c0 .99.23 1.75.7 2.28s1.15.79 2.02.79c.6 0 1.13-.09 1.6-.26v-.84c-.26.08-.51.14-.74.19c-.24.05-.49.08-.74.08c-.59 0-1.04-.19-1.34-.57c-.32-.37-.47-.93-.47-1.66c0-.7.16-1.25.48-1.65c.33-.4.77-.6 1.33-.6zM6.5 8h1.04L5.3 2H4.24L2 8h1.03l.58-1.66H5.9zM8 2v6h2.17c.67 0 1.19-.15 1.57-.46c.38-.3.56-.72.56-1.26c0-.4-.1-.72-.3-.95c-.19-.24-.5-.39-.93-.47v-.04c.35-.06.6-.21.78-.44c.18-.24.28-.53.28-.88c0-.52-.19-.9-.56-1.14c-.36-.24-.96-.36-1.79-.36H8zm.98 2.48V2.82h.85c.44 0 .77.06.97.19c.21.12.31.33.31.61c0 .31-.1.53-.29.66c-.18.13-.48.2-.89.2h-.95zM5.64 5.5H3.9l.54-1.56c.14-.4.25-.76.32-1.1l.15.52c.07.23.13.4.17.51zm3.34-.23h.99c.44 0 .76.08.98.23c.21.15.32.38.32.69c0 .34-.11.59-.32.75s-.52.24-.93.24H8.98V5.27zM4 13l5 5l9-8l-1-1l-8 6l-4-3z" /></svg>
                Debilní ankety
            </a>
            <a href="https://paralelnilisty.cz/e-shop/">
                <svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" preserveAspectRatio="xMidYMid meet" viewBox="0 0 20 20"><path fill="currentColor" d="M6 13h9c.55 0 1 .45 1 1s-.45 1-1 1H5c-.55 0-1-.45-1-1V4H2c-.55 0-1-.45-1-1s.45-1 1-1h3c.55 0 1 .45 1 1v2h13l-4 7H6v1zm-.5 3c.83 0 1.5.67 1.5 1.5S6.33 19 5.5 19S4 18.33 4 17.5S4.67 16 5.5 16zm9 0c.83 0 1.5.67 1.5 1.5s-.67 1.5-1.5 1.5s-1.5-.67-1.5-1.5s.67-1.5 1.5-1.5z" /></svg>
                vlastenecký eshop
            </a>
        </div>
    </>
}